.confirmation_container{
    width: 100%;
    // .confirm_item_container{
    //     display: flex;
    //     flex-direction: column;
    // }
    .big_label{
        line-height: 1.6;
        margin-bottom: 12px;
        span{
            margin-right: 4px;
        }
        .name{
            display: inline-block;
            ;
        }
    }
    
    .label_hint{
        margin-top: 32px;
    }
    .info_container{
        margin-bottom: 32px;
    }
}