@import '../../sass/colors.scss';
@import '../../sass/sizes.scss';

.form_wrapper{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 414px;
    margin: 20px auto;
    @media screen and (max-width: 480px) {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .container{
        padding: 32px;
        width: 100%;
        height: initial;
        box-sizing: border-box;
        justify-content: flex-start;
    }
    .form_content_container{
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &_title{
        width: 100%;
    }
}