.form_header{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
    .btn{
        margin-right: 10px;
    }
    .progress-bar{
        width: 100%;
    }
}