.loader-wrapper{
	background: rgba(18, 18, 18, 0.85);
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
}

.loader{
	width: 40px;
}

.loading-animation__path{
	stroke: white;
	stroke-width: 1;
	fill: none;
	stroke-dasharray: 500;
	stroke-dashoffset: 500;
	animation: stroke 3.5s linear infinite;
}

@keyframes stroke {
	from {
		stroke-dashoffset: 500;
	}
	to {
		stroke-dashoffset: 0;

	}
}

.loading_message{
	position: absolute;
    top: 59%;
    left: 50%;
	color: white !important;
	z-index: 10 !important;
	text-align: center;
    transform: translate(-50%, -50%);
	@media screen and (max-width: 560px) {
		width: 100%;
	  }
}