@import '../../../sass/colors.scss';
.form_footer{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
    box-sizing: border-box;
    height: 35px;
    flex-wrap: wrap;
    .icon-wrapper{
        padding: 1px;
        height: 32px;
        width: 32px;
        margin-right: 16px;
        border-radius: 50%;
        display:flex;
        align-items:center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border: 1px solid #555555;
    }
    &_anchor{
        text-decoration: none;
        color: $textBlack;
        font-size: 14px;
    }
    &_phone_number{
        padding: 2px 0;
        display: flex;
        align-items: center;
        border-right: 1px solid $borderGray;
        padding-right: 39px;
        @media screen and (max-width: 480px) {
            border-right: none;
            margin-bottom: 20px;
        }
    }
    &_email{
        padding: 2px 0;
        display: flex;
        align-items: center;
    }
}