.code_verification_container{
    &_send_code{
        display: flex;
        align-items: center;
        &_button{
            cursor: pointer;
            margin-left: 10px;
        }
        .custom_icon{
            margin-right: 10px;
        }
    }
    &_confirm_message{
        margin-top: 24px;
        margin-bottom: 8px;
    }
    .send_again_icon{
        cursor: pointer;
    }
    
}