.error_popup{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &_message{
        margin-top: 24px;
        margin-bottom: 14px;
        text-align: center;
    }
    .icon-wrapper{
        padding: 1px;
        height: 60px;
        width: 60px;
        margin-right: 16px;
        border-radius: 50%;
        display:flex;
        align-items:center;
        justify-content: center;
        border: 2px solid #D2D2D2;
        border-radius: 50%;
    }
}

