@import '../../sass/colors.scss';
.loan_confirmation{
    &_divider{
        width: 100%;
        height: 1px;
        background-color: #BBBBBB;
        margin: 16px 0;
    }
    &_info_container{
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }
    &_profile_info_container{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .withBottomMargin{
        margin-bottom: 24px !important;
    }
    .halfWidth{
        min-width: 40%;
    }
    .custom_checkbox_container{
        margin-top: 24px;
    }
    .loan_details_link{
        text-decoration: none;
        color: $buttonGreen;
    }
    .loan_confirmation_info_container{
        align-items: flex-start;
        .info_item_container{
            width: 50%;
            margin-right: 0;
        }
    }
}
