
@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.form_body{
  width: 100%;
  .form_field_container{
      width: 100%;
      .input{
        width: 100%;
      }
  }
}

@media screen and (max-width: 560px) {
  .dialog{
    min-width: 300px !important;
  }
}
@media screen and (max-width: 560px) {
  .popup-content{
    margin: 0 auto;
    min-width: 350px !important;
  }
}

.container {
  border-radius: 4px;
  box-shadow: 0px 5px 20px rgba(14, 14, 14, 0.06);
}


