.countdown_container{
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    .message{
        margin-right: 15px;
        margin-bottom: 3px;
    }
    .minutes{
        font-weight: 500;
    }
}