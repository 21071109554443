.congratulation_container{
    max-width: 680px;
    margin: 0 auto;
    margin-top: 100px;
    &_content{
        padding: 30px;
        box-sizing: border-box;
        min-height: 350px;  
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    &_title{
        margin-top: 40px;
        margin-bottom: 8px;
    }
    &_info_text{
        margin-bottom: 15px;
        max-width: 430px;
        text-align: center;
    }
    &_back_button{
        margin-top: 24px;
        min-width: 334px
    }
    .icon-wrapper{
        padding: 1px;
        height: 60px;
        width: 60px;
        margin-top: 30px;
        border-radius: 50%;
        display:flex;
        align-items:center;
        justify-content: center;
        border: 2px solid #D2D2D2;
        border-radius: 50%;
    }

}