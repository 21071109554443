

.personal_details_container{
    width: 100%;
    .info_container{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        margin-bottom: 24px;
        margin-top: 16px;
        flex-wrap: wrap;
       .email_info{
        margin-bottom: 32px;
       }
    }
    .form_container{
        @media screen and (max-width: 480px) {
            width: 100%;
            box-sizing: border-box;
            justify-content: space-between;
        }
    }
 
    
}