.personal_address_container{
    width: 100%;
    &_title{
        font-weight: 500;
        margin-bottom: 16px;
    }
        .loan_filled_info_container{
            display: flex;
            align-items: center;
            margin-bottom: 32px;
            .overflowWrapper{
                width: 50%;
                display: flex;
                align-items: flex-end;
                .info_item_container{
                    max-width: 87%;
                    width: auto;
                }
                .info_text{
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: pre;
                }
            }
        }
        .personal_address_container_title{
            margin-top: 40px;
        }
        .info_item_container{
            width: 50%;
            margin-right: 0;
        }
        
}