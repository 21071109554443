$black: #000000;
$borderGray:#BBBBBB;
$labelGray:#616161;
$justWhite:#FFFFFF;
$textBlack:#0E0E0E;
$dropdownGray:#8F9096;
$inputWhite:#FAFAFA;
$inputBorderGray:#DDDDDD;
$inputBorderActive:#72BF44;
$inputBorderError:#E14A4A;
$buttonGreen:#72BF44;
$secButtonBorderGray:#BBBBBB;
$onGray:#616161;
$footerTextGray:#797979;
$footerUnderlineGray:#ECECEC;
$darkGray:#7B7C82