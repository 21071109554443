.header{
    padding: 0 30px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logout_button{
        cursor: pointer;
        display: flex;
        align-items: center;
        .buttonText{
            margin-left: 8px;
        }
        a {
            display: flex;
            align-items: center;
            text-decoration: none;
        }
    }
    &_logo{
        padding: 10px 20px;
        @media screen and (max-width: 480px) {
            width: 200px;
        }
    }
    @media screen and (max-width: 480px) {
        margin-bottom: 24px;
        padding: 0;
    }
}