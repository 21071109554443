.submit_button_container{
    width: 100%;
    text-align: center;
    margin-top: 24px;
    .submit-button{
        max-width: initial;
        width: 100%;
    }
    @media screen and (max-width: 480px) {
        margin: 20px 0;
        box-sizing: border-box;
    }
}