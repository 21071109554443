@import '../../sass/colors.scss';

.personal_data_container{
    width: 100%;
    .more_link{
        text-decoration: none;
        color: $inputBorderActive;
        font-size: 14px;
    }
    .aqra_checkbox_lable{
        p{
            white-space: pre-wrap;
        }
    }
}
.form_container{
    @media screen and (max-width: 480px) {
        width: 100%;
        box-sizing: border-box;
        justify-content: space-between;
    }
}